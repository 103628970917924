a,
a:visited,
a:hover,
a:active {
  color: inherit;
  padding: 0px !important;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.animated-button {
  opacity: 0;
}

.animated-button.slide-in {
  animation: slideInFromLeft 0.3s ease-in-out forwards;
}
